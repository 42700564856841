<template>
  <div :class="containerClass">
    <div class="settings-title mb-3 mt-3">
      {{ $t('components.form.subscriptions.new.subscription-rates') }}
    </div>
    <template
      v-for="(subscriptionPlanPrice, i) of subscriptionPlan.prices"
    >
      <rates-form
        :key="'rate-form-key' + i"
        :index="parseInt(i)"
        :validator="validator"
        :violations="violations"
        :is-disabled="isDisabled"
        :subscription-plan-price="subscriptionPlanPrice"
        @on:remove-rate-click="removeRate"
      />
    </template>
    <AddRate
      v-if="!isDisabled"
      class="mt-3"
      text-link="components.form.subscriptions.new.add-new-rate"
      @on:add-link-click="newRate"
    />
  </div>
</template>
<script>
import SubscriptionPlanPrice from "@/classes/doinsport/SubscriptionPlanPrice";
import {deleteSubscriptionPlanPrice} from "@api/doinsport/services/subscription-plans/subscription-plan-price/subscription-plan-price";

export default {
  components: {
    RatesForm: () => import('@form/subscriptions/new/subscription-rates/RatesForm'),
    AddRate: () => import('@custom/subscrtiptions/new/AddItemLink')
  },
  props: {
    validator: {
      type: Boolean,
      default: true,
    },
    violations: {
      type: Array,
      default: () => [],
    },
    containerClass: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    subscriptionPlan: {
      type: Object,
      default: () => {
      },
    }
  },
  methods: {
    newRate() {
      this.addSubscriptionPlanPrice()
    },
    removeRate(scope) {
      this.subscriptionPlan.prices.splice(this.subscriptionPlan.prices.indexOf(scope), 1);
      if (scope.id) {
        deleteSubscriptionPlanPrice(scope.id);
      }
    },
    addSubscriptionPlanPrice() {
      this.subscriptionPlan.prices.push(new SubscriptionPlanPrice());
    },
    resetSubscriptionPlanPrices() {
      this.subscriptionPlan.prices = [];
    }
  },
  created() {
    // on create
    if (null === this.subscriptionPlan.id) {
      this.resetSubscriptionPlanPrices();
      this.addSubscriptionPlanPrice();
    } else {
      // TODO on update
    }
  }
}
</script>
